import React from "react"
import classnames from "classnames"

function Banner({ children, hasCard, imageComponent, label }) {
  return (
    <section
      className={classnames("bg-indigo-900  text-gray-100", {
        "bg-cover bg-center md:h-64 relative": imageComponent,
        "border-t-8 border-gray-400": !imageComponent,
      })}
    >
      <div
        className={classnames("container mx-auto", {
          "px-3 py-6 lg:py-10": !hasCard,
        })}
      >
        {hasCard ? (
          <div className="block md:inline-block sm:max-w-xl md:mt-24 md:shadow-xl bg-indigo-900 text-gray-200 py-4 px-5 serif z-50 relative">
            <h2 className="text-lg md:text-2xl xl:text-4xl font-semibold">
              {label}
            </h2>
            {children}
          </div>
        ) : (
          <>
            <span className="text-gray-400 font-semibold tracking-widest uppercase text-sm lg:text-base lg:pl-1">
              {label}
            </span>
            <h2 className="max-w-4xl serif text-xl lg:text-5xl font-bold mt-3">
              {children}
            </h2>
          </>
        )}
      </div>
      {imageComponent && imageComponent()}
    </section>
  )
}

export default Banner
